










































import { Vue, Component, Prop } from 'vue-property-decorator'
import MutationForm from '@/components/form/MutationForm.vue'
import getEnv from '@/plugins/getEnv'

@Component({
  components: { MutationForm }
})
export default class EnvironmentUserCreate extends Vue {
  desktopApp = getEnv('VUE_APP_IS_DESKTOP')
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Accent Color */
  @Prop({ type: String, default: 'primary' }) accentColor!: string
}
